@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&family=Play:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Round");
@import "./variables.scss";

/* shaigarusi.com styles*/
html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow: hidden;
}
/* General Styles & Title */
body {
  font-family: $font-2;
  color: $white;
  padding: 0 0 1px 0;
  margin: 0;
  // background: #0b1112;
  background: $primary-dark;
  scroll-behavior: smooth;
  overflow: hidden;
}

.shaigarusiApp {
  user-select: none;
  z-index: 1;
  width: 100%;
  // height: calc(100% - 40px);
  height: 100%;
  margin: 0 auto;
  overflow: hidden;

  .App {
    height: 100%;
    width: 100%;
    position: relative;
    // grid-template-columns: 100%;
    // grid-template-rows: 16% 84%;
    // grid-row-gap: 0;
    // display: grid;
    display: flex;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1366px;
  }
}

.modal-image-top-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  background: transparent;
  user-select: none;
}
.absolute-fullscreen {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

// Texts Sizes & Styles //
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  line-height: 130%;
}
h1 {
  font-size: 2.625rem; // 42px
}
h2 {
  font-size: 1.625rem; // 26px  
}
h3 {
  font-size: 1.25rem; // 20px
}

h4, p,
a, span,label,
input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select {
  font-size: 1rem; // 16px
}
a {
  text-decoration: none;
  outline: none;
}
p , span {
  line-height: 138%;
}

.sm-text {
  font-size: 0.75rem; // 12px

}
.headline {
  padding-bottom: 2vh;
  padding-inline-end: 4px;
  font-weight: 700;
  color: $white;
  font-family: $font-1;
  width: fit-content;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 4px;
    background: $secondary;
    position: absolute;
    bottom: calc(2vh - 8px);
    left: 0;
    border-radius: $borderadius-1;
    background: linear-gradient(-45deg, #{$secondary-light} 30%, #{$secondary}, #{$secondary} 70%);
    background-size: 200% 200%;
    animation: GlowingBackground 6s ease infinite;
  }
}


// Portrait Headlines

.shaigarusiApp {
  &.portrait {
    .App {
      button {
        height: 4vh;
        padding: 1vh 4vw;
      }
    }
  }
  &.mobile {
    .headline {
      &:before {
        content: "";
        height: 2px;
        bottom: calc(2vh - 6px);
      }
      // text-decoration: underline solid $secondary 4px;
      // text-underline-offset: 6px;
    }
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    h3 {
      font-size: 1rem;
    }
    h4,
    p,
    a,span,label{
      font-size: 0.875rem;
    }
    input[type="text"],
    input[type="email"],
    input[type="number"],
    textarea,
    select {
      font-size: 0.875rem;
    }
    .sm-text {
      font-size: 0.75rem;
    }
    button {
      min-width: 40px;
      font-size: 0.75rem;
      border-radius: $borderadius-3;
    }
    .overlaybox {
      .continue-button {
        i.material-icons-round {
          // font-size: 18px;
          line-height: 1;
          margin-top: -12px;
        }
      }
    }
    .material-icons-round {
      // font-size: 24px;

      &.inner-icon {
        font-size: 18px;
        padding-inline-end: 4px;
      }
    }
  }
}
// Form Styles
input[type="checkbox"] {
  zoom: 1.3;
  margin: 4px;
}
input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select {
  font-size: 1rem;
  font-family: $font-2;
  width: 100%;
  padding: $spacing-3 $spacing-4;
  margin: 0;
  display: inline-block;
  border: 1px solid transparent;
  // border: none;
  outline: none;
  background: $bg-overlay-2;
  color: $white;
  border-radius: $borderadius-3;
  box-sizing: border-box;
  &:focus {
    background: $bg-overlay-3;
    outline: none !important;
    color:$secondary-light;
    border: 1px solid $secondary-light;
  }
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98.5%;
  background-position-y: 48%;
}
label {
      text-transform: capitalize;
  display: block;
}
textarea {
  padding: $spacing-3 $spacing-4;
}
a {
  margin: 0;
}


.s-btn {
  min-width: 40px;
  width: 10vw;
  max-width: 10vh;
}
.m-btn {
  width: 24vw;
  max-width: 24vh;
}
.full-btn {
  width: 100%;
  max-width: 100%;
}


button {
  font-family: $font-1;
  height: 6vh;
  min-width: 72px;
  min-height: 40px;
  max-height: 72px;
  line-height: 1.125;
  padding: 1vh 2vw;
  margin:0;
  width: 100%;
  border: 0;
  border-radius: $borderadius-4;
  background: $primary;
  color: $secondary;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.03rem;
  font-size: 0.75rem;
  box-shadow: 0 4px 8px rgba(5, 5, 23, 0.12);
  transition: all 0.1s ease-out;
  z-index: 1;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover {
    background: $primary-light;
    color: $white;
    box-shadow: 0 2px 10px rgba(5, 5, 23,0.4);
    transition: all 0.2s ease-in;
  }
  // &:active {
  //   transform: scale(0.9);
  // }
  &:focus {
    outline: none;
  }
  &.disabled {
    color: $primary-dark;
    background: $primary;
    box-shadow: none;
    opacity: 0.5;
    &.primary-btn {
      color: $primary-dark;
      background: $dark-grey;
      box-shadow: none;
      &:hover {
        color: $primary-dark;
        background: $dark-grey;
        box-shadow: none;
      }
    }
  }
  &.primary-btn {
    background: $secondary;
    color: $primary-dark;
    box-shadow: 0 4px 8px rgba(255, 227, 76,0.2);
    transition: all 0.2s ease-out;
    z-index: 10;

    &:hover {
      transition: all 0.2s ease-in;
      background: $secondary-light;
      color: $primary;
      box-shadow: 0 2px 10px rgba(255, 227, 76, 0.4);
      &.glow-btn {
        animation: none;
      }
    }
  }
  &.glow-btn {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        -65deg,
        rgba(255,255,255, 0)0%,
        rgba(255,255,255, 0)40%,
        rgba(255,255,255, 0.1)45%,
        rgba(255,255,255, 0.3)50%,
        rgba(255,255,255, 0.1)55%,
        rgba(255,255,255, 0)60%,
        rgba(255,255,255, 0) 100%,
      );
      background-size: 400% 400%;
      animation: GlowingBackground 12s ease infinite;
      position: absolute;
      border-radius: $borderadius-4;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 1;
    }
  }
  &.bob-anim {
    animation: float 1.6s ease-in-out infinite;
  }
  &.active-btn {
    background: $secondary-dark;
    color: $white;
  }
}
.error {
  color: $error;
}
.success {
  color: $success;
}
.text-btn {
  user-select: none;
  color: $white;
  border: none;
  box-shadow: none;
  background: transparent;
  &:hover{
    background: transparent;
  color: $white;
  box-shadow: none;
  }
  &:before {
    content: "";
    width: 0;
    height: 2px;
    background: transparent;
    position: absolute;
    bottom: -4px;
    // bottom: calc(2vh - 8px);s
    left: 0;
    border-radius: $borderadius-1;
    background-size: 200% 200%;
    animation: GlowingBackground 6s ease infinite;
    transition: width 0.3s ease-out;
  }
  &:hover {
    transition: width 0.3s ease-in;
    &:before {
    width: 100%;
    content: "";
    background: linear-gradient(-45deg, #{$secondary-light} 30%, #{$secondary}, #{$secondary} 70%);
    }
  }
}
/* progress bar styles */
.progress-bar {
  height: 5px;
  background: $secondary;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

// Buttons & Icons Styles

.material-icons-round {
  font-size: 36px;
  &.md-18 {
    font-size: 18px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-36 {
    font-size: 36px;
  }
  &.md-48 {
    font-size: 48px;
  }
  &.md-64 {
    font-size: 64px;
  }
}

.inner-icon {
  padding-inline-end: 8px;
}

.icon-anim {
  transition: all 0.2s ease-out;
  &:hover {
    transition: all 0.2s ease-in;
    transform: scale(1.2);
  }
}

.main-wrapper {
  padding: 0;
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
  // transition: transform 0.3s ease-in;
  // overflow-x: hidden;
  transform: translate(0);
  transition: all 0.4s ease-in;
  &.ma-on {
    animation: pers-anim 0.6s ease-out;
    transform: perspective(1400px) rotateY(-20deg) translateY(0) translateX(20%) translateZ(200px) scale(0.33);
    background: $primary;
      z-index: 400;
      border-radius: $borderadius-5;
        overflow: hidden;
    }
}
.pers-shadow, .shadow-two {
  opacity: 0.6;
  animation: shadow-one 0.5s ease-in;
  border-radius: $borderadius-5;
  position: absolute;
    width: 100%;
    height: 100%;
    // height: 84%;
  right: 0;
  bottom: 0;
  transform: perspective(1400px) rotateY(-20deg) translateY(-1%) translateX(23%) translateZ(50px) scale(0.33);
  background: $primary;
  z-index: 390;
  &.shadow-two {
  animation: shadow-two 0.5s ease-in;
  z-index: 380;
    transform: perspective(1400px) rotateY(-20deg) translateY(-3%) translateX(26%) translateZ(-200px) scale(0.33);
  }
}

.overlaybox {
  z-index: 901;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 12, 30, 0.25);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: baseline;
}

// Mobile Styles
.shaigarusiApp {
  &.mobile {
    .inner-icon {
      padding-inline-end: 4px;
    }
    .s-btn {
      width: 14vw;
      max-width: 14vh;
    }
    .m-btn {
      // width: 42vw;
      // max-width: 42vh;
      width: 38vw;
    max-width: 38vh;
    }
    .full-btn {
      width: 100%;
      max-width: 100%;
    }
  }
}

// @media only screen and (max-width: 500px) {
//   html { font-size:50%; -webkit-text-size-adjust:none; }
// }


@keyframes pers-anim {
  0%{
      background: $primary;
      transform: translate(0);
    }
    20%{
      }
  100%{
  transform: perspective(1400px) rotateY(-20deg) translateY(0%) translateX(20%) translateZ(200px) scale(0.33);
  }
}
@keyframes shadow-one {
  0%{
    transform: translate(0);
    opacity: 0;
}
10%{
      transform: perspective(1400px) rotateY(-20deg) translateY(0%) translateX(20%) translateZ(200px) scale(0.33);
}
  100%{
      transform: perspective(1400px) rotateY(-20deg) translateY(-1%) translateX(23%) translateZ(50px) scale(0.33);
      opacity: 0.6;
    }
}
@keyframes shadow-two{
  0%{
    opacity: 0;
    transform: translate(0);
}
20%{
    transform: perspective(1400px) rotateY(-20deg) translateY(-1%) translateX(23%) translateZ(50px) scale(0.33);
}
  100%{
      transform: perspective(1400px) rotateY(-20deg) translateY(-3%) translateX(26%) translateZ(-200px) scale(0.33);
      opacity: 0.6;
    }
}



@keyframes float {
	0% {
    transform: translatey(0px);
    box-shadow: 0 4px 8px rgba(255, 227, 76,0.2);
	}
	50% {
    transform: translatey(-8px);
    box-shadow: 0 12px 16px rgba(255, 227, 76,0.12);
	}
	100% {
    transform: translatey(0px);
    box-shadow: 0 4px 8px rgba(255, 227, 76,0.2);
	}
}