@import "../variables.scss";

.form-message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #{$bg-overlay-1}, transparent);
  width: 56%;
  max-width: 864px;
  padding: 24vh 4% 4vh 4%;
  height: fit-content;
  margin: 0;
  border-radius: 0 0 $borderadius-4 $borderadius-4;
  min-height: auto;
  text-align: center;
  p {
    color: $white;
  }
  .redirect-time-left {
    color: $primary-light;
  }
}

// Portrait Styles
.shaigarusiApp {
    &.portrait {
      .form-message-wrapper {
        padding: 14vh 8% 4vh 8%;
      }
    }
  }
