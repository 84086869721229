@import "../variables.scss";

.error-page-wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 4% 6%;
    .error-msg {
        padding-block-end: 1%;
        p {
            margin: 0;
            padding: 1% 0;
        }
    }
    .buttons-wrapper {
        width: 100%;
        a {
        text-decoration: none;
          margin-inline-end: 4vw;
          color: $white;
          text-decoration: underline;
          &:last-of-type {
              margin: 0;
          }
        }
      }
}