@import "../variables.scss";

.items-grid-wrapper {
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: width 0.2s ease-out;
  margin: 0;
  .vertical {
    list-style: none;
      transition: all 0.3s ease-in;
      padding: 20vh 6% 8% 6%;
    // padding: 2% 6% 8% 6%;
    margin: 0;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 100%;
    .item-wrapper {
      width: calc(92% / 2);
      height: 64%;
      margin: 2%;
    }
  }
  .item-wrapper {
    position: relative;
    z-index: 150;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: $borderadius-4;
    transition: all 0.3s ease-out;
    // box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    &:hover {
      transition: all 0.3s ease-in;
      // box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
      .item-image-wrapper {
        transition: all 0.3s ease-out;
        .item-image {
          transform: scale(1.2) translateY(4px) translateX(4px);
        }
      }
      .item-caption {
        padding: 4vh 0 calc(4vh + 4px) 0;
      }
    }
    &.opened {
      transition: all 0.3s ease-out;
      .item-image-wrapper {
        background: $primary;
        .item-image {
          filter: grayscale(1);
          opacity: 0.2;
        }
      }
      &:hover {
        transition: all 0.3s ease-in;
        .item-image {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }
    a {
      height: 100%;
    }
    .item-image-wrapper {
      width: 100%;
      height: 100%;
      z-index: 21;
      border-radius: $borderadius-4;
      overflow: hidden;
      background: $primary;
      .item-image {
        z-index: 22;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 100%;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        transition: all 0.3s ease-out;
      }
    }
    .item-caption {
      z-index: 23;
      width: 100%;
      padding: 4vh 0;
      height: fit-content;
      min-height: 20%;
      max-height: 60%;
      display: flex;
      flex-direction: column;
      // text-align: center;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease-out;
      background: linear-gradient(to top, #{$bg-overlay-dark} 0%, transparent 100%);
      border-radius: 0 0 $borderadius-4 $borderadius-4;
      // border-radius: 0 0 calc(#{$borderadius-4} + 4px) calc(#{$borderadius-4} + 4px);
      h3,
      span {
        padding: 2px 8%;
        max-width: 84%;
        z-index: 80;
        text-shadow: 0 1px 4px $bg-overlay-dark;
      }
      h3 {
        font-weight: 700;
        // text-overflow: ellipsis;
      }
      span {
        font-weight: 400;
        color: $secondary;
      }
    }

    &.pop-item {
      // background: linear-gradient(-180deg, #{$primary-light} 40%, #{$primary} 100%);
      // background: $primary;
      cursor: auto;

      margin-top: 4%;
      height: fit-content;
      width: 100%;
      justify-content: center;
      padding: 4vh 0;
      .btn-wrapper {
        text-decoration: none;
        margin: 0;
        padding: 0 0 2vh 0;
        button {
          margin-top: 3vh;
        }
      }

      .item-caption {
        background: transparent;
        align-items: center;
        position: relative;
        text-align: center;
        padding: 2vh 0;
        // h3, span {
        //   text-shadow: none;
        // }
        // h3 {
        //   color: $primary;
        // }

        span {
          padding: 1vh 8%;
        }
        :first-child {
          padding-top: 0;
        }
      }
    }
  }
  .list-end {
    width: 100%;
    text-align: center;
    color: $primary-light;
    padding: 2vh 8%;
    margin: 2vh 0;
  }
}

// Mobile Styles
.shaigarusiApp {
  &.mobile {
    .main-wrapper {
      .items-grid-wrapper {
        .vertical {
          .item-wrapper {
            &.opened {
              &:hover {
                .item-image {
                  filter: grayscale(1);
                  opacity: 0.2;
                }
              }
            }
            &:hover {
              .item-image-wrapper {
                .item-image {
                  transform: none;
                }
              }
            }
            .item-caption {
              border-radius: 0 0 calc(#{$borderadius-4} - 4px) calc(#{$borderadius-4} - 4px);
            }
          }
        }
      }
    }
  }
}

// Portrait Styles
.shaigarusiApp {
  &.portrait {
    .main-wrapper {
      .items-grid-wrapper {
        width: 100%;
        .vertical {
          padding: 12vh 6% 8% 6%;
          .item-wrapper {
            width: 96%;
            height: 46%;
            margin: 4%;
            .item-caption {
              min-height: 30%;
              max-height: 60%;
              padding: 2vh 0;
            }
            &:hover {
              .item-caption {
                padding: 2vh 0;
              }
            }
            &.pop-item {
              padding: 0;
              height: fit-content;
              min-height: 32%;
              max-height: 46%;
              .item-caption {
                padding: 2vh 0 0 0;
                min-height: auto;
                max-height: auto;
              }
            }
          }
        }
      }
    }
  }

  &.mobile:not(.portrait) {
    .main-wrapper {
      .items-grid-wrapper {
        .vertical {
          padding-top: 20vh;
          .item-wrapper {
            &.pop-item {
              padding: 8vh 0;
            }
          }
        }
      }
    }
  }
}

.hs {
  &::-webkit-scrollbar {
    width: 16px;
    height: 0;
  }
}

.main-wrapper {
  &.ma-on {
    .vertical {
        overflow: hidden;
        padding: 8% 6%;
      transition: all 0.3s ease-out;
    }
  }
}
