@import "../variables.scss";

.about-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
    overflow-y: scroll;
    .about-content-wrapper {
      transition: all 0.3s ease-in;
    padding: 24vh 4% 4vh 4%;
    text-align: left;
    min-height: -webkit-fill-available;
    // background: linear-gradient(to top, #{$bg-overlay-1}, transparent );
    width: 64%;
    max-width: 864px;
    height: fit-content;
    margin-bottom: 8vh;
    transform: none;
    border-radius: $borderadius-5;
    min-height: auto;
    .headline,
    .about-text,
    .status-text {
      width: fit-content;
      margin: 0;
      display: block;
    }
    .status-text {
      color: $success;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 4px;
      margin: 1vh 0 2vh 0;
      // border-radius: $borderadius-3;
      // border: 1px solid $success;
      .dot-blink {
          width: 8px;
          height: 8px;
          margin-inline-end: 12px;
          background-color: $success;
          border-radius: 50%;
        animation: blink 1.8s infinite;
      }
    }
    .about-text {
      &:last-of-type {
        margin-bottom: 4%;
      }
    }


    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2vh 0;
      width: fit-content;
      margin: 0;
      // margin: 0 auto;
      .linkedin-icon {
        width: 20px;
        height: 20px;
      }
      .contact-link {
        text-decoration: none;
        margin-right: 8%;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
        .linkedin-btn {
          padding: 0 16px;
        }
      }
    }
  }
}

// Portrait Styles
.shaigarusiApp {
  &.portrait {
    .about-wrapper {
  .about-content-wrapper {
    padding: 14vh 8% 4vh 8%;
    margin-bottom: 4vh;
        .headline,
        .about-text,
        .status-text {
          max-width: 100%;
        }
        .about-text {
          &:last-of-type {
            margin-bottom: 8%;
          }
        }
        // .status-text {
        //   margin: 4% 0;
        // }
        .buttons-wrapper {
          padding: 4% 0;
          a {
            width: 36vw;
          }
        }
      }
    }
    // &.mobile {
    //   .about-wrapper {
    //     .about-content-wrapper {
    //       padding: 4vh 8%;
    //       .headline,
    //       .about-text,
    //       .status-text {
    //         max-width: 100%;
    //       }
    //       .about-text {
    //         &:last-of-type {
    //           margin-bottom: 2vh;
    //           }
    //       }
    //     }
    //   }
    // }
  }
}

// Mobile Styles
.shaigarusiApp {
  &.mobile {
    .about-wrapper {
      .about-content-wrapper {
        .headline,
        .about-text,
        .status-text {
          max-width: 100%;
        }
        .about-text {
          &:last-of-type {
          margin-bottom: 2%;
          }
        }

        .buttons-wrapper {
          .linkedin-icon {
            width: 20px;
            height: 20px;
          }
          .contact-link {
        margin-right: 8%;
          }
        }
      }
    }
  }
}


@keyframes blink {
  0% { opacity: 0; transform: scale(0.6);}
  50% { opacity: 1; transform: scale(1);}
  100% { opacity: 0; transform: scale(0.6);}
}

.main-wrapper {
  &.ma-on {
    .about-wrapper {
        overflow: hidden;
        .about-content-wrapper {
        transition: all 0.3s ease-out;

      padding: 8% 4% 4vh 4%;}
  }
  }
}


