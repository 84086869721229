@import "../variables.scss";

.contact-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
    overflow-y: scroll;
  .contact-form {
        transition: all 0.3s ease-in;
        // background: linear-gradient(to top, #{$bg-overlay-1}, transparent);
    width: 64%;
    max-width: 864px;
    padding: 24vh 4% 4vh 4%;
    margin-bottom: 8vh;
    height: fit-content;
    border-radius: $borderadius-5;
    min-height: auto;
    text-align: left;
    .to {
      padding-inline-end: 8px;
      color: $primary-light;
    }
    .to-email-address {
      width: 100%;
      border-bottom: 1px solid $bg-overlay-1;
  padding-bottom: 2vh;
  color: $secondary;
        text-align: left;
        display: flex;
        align-items: center;
        
      .to-address {
        color: $white;
        padding: 8px 4px;
      }
      .copy-i {
        padding: 8px 4px;
        margin-inline-start: 4px;
        cursor: pointer;
      }
      .copy-success {
        margin-inline-start: 4px;
        padding: 8px;
        background: $primary-light;
        border-radius: $borderadius-3 $borderadius-3 $borderadius-3 0;
      }
    }
    
    .split ~ .split:not(:first-child) {
      margin:0;
      float: none;
    }
    .input-wrapper {
      position: relative;
      padding: 2vh 0 1vh 0;
      display: block;
      clear: both;
      float: none;
            width: 100%;
          margin: 0;
    &.split {
      clear: none;
      display: flex;
      flex-direction: column;
      float: left;
      width: 49%;
    margin-inline-end: 2%;
  }
      .form-label {
          z-index: 20;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          margin-bottom: 2vh;
      }
      .form-val-i {
        position: absolute;
        right: 16px;
        bottom: calc(-2vh - 32px);
        padding: 0;
        pointer-events: none;
        color: $secondary-light;
      }
    }
    .form-input {
      z-index: 25;
      padding-right: 48px;
    }
    
    .input-val-t {
      display: flex;
      justify-content: flex-end;
      color: $secondary-light;
      padding: 1vh 16px 0 16px;
    }
    .send-btn {
      margin: 4vh 0 2vh 0;
    }
  }
}

.shaigarusiApp {
  &.portrait {
    .contact-wrapper {
      .contact-form {
    margin-bottom: 4vh;
    padding: 14vh 8% 4vh 8%;
        .input-wrapper {
          .form-val-i {
            bottom: calc(-2vh - 30px);
          }
        }
        .send-btn {
          margin: 2vh auto 0 0;
        }
      }
    }
  }

  // Mobile

  &.mobile {
    .contact-wrapper {
      .contact-form {
    .input-wrapper {
          .form-val-i {
            bottom: calc(-2vh - 30px);
          }
        }
        
      }
    }
  }
}


.main-wrapper {
  &.ma-on {
    .contact-wrapper {
      overflow: hidden;
      .contact-form {
        padding: 8% 4% 4vh 4%; 
        transition: all 0.3s ease-out;
      }
      }
  }
}

