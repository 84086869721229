//Fonts
$font-1: "Orbitron", sans-serif;
$font-2: "Play", sans-serif;

// Main Colors
$primary: #2e2f3d;
$primary-dark: #0a0c1e;
$primary-light: #575867;
$secondary: rgb(255, 177, 0);
$secondary-light: rgb(255, 227, 76);
$secondary-dark: rgb(198, 130, 0);

$black: rgb(18, 18, 18);
$white: rgb(240, 240, 240);
$light-grey: #dddddd;
$dark-grey: #5a5a5a;
$bg-overlay-light: rgba(87, 88, 103, 0.8);
$bg-overlay-dark: rgba(10, 12, 30, 0.8);

// Old
$bg-overlay-1: rgba(255, 255, 255, 0.07);
$bg-overlay-2: rgba(255, 255, 255, 0.11);
$bg-overlay-3: rgba(255, 255, 255, 0.14);
$bg-overlay-4: rgba(255, 255, 255, 0.18);
$bg-overlay-5: rgba(255, 255, 255, 0.24);

// Extra Colors
$error: #D62828;
$success: #97CC04;

$text-shadow-light: 1px 1px 1px $bg-overlay-light;
$text-shadow-dark: 1px 1px 1px $bg-overlay-dark;

$box-shadow-light:0 0 2px $bg-overlay-light;
$box-shadow-dark: 0 0 2px $bg-overlay-dark;
// Layout
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 24px;
$spacing-6: 32px;
// Design
$borderadius-1: 2px;
$borderadius-2: 4px;
$borderadius-3: 8px;
$borderadius-4: 16px;
$borderadius-5: 24px;
.GlowingBackgroundAnimation {
	content: "";
	position: absolute;
	bottom: 0;
	right: 0;
	background: linear-gradient(
	  -65deg,
	  $bg-overlay-5,
	  $bg-overlay-3,
	  $bg-overlay-2 45%,
	  $bg-overlay-5 50%,
	  $bg-overlay-2 55%,
	  $bg-overlay-3,
	  $bg-overlay-5
	);
	background-size: 400% 400%;
	animation: GlowingBackground 12s ease infinite;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -2;
	opacity: 1;
  }

@keyframes GlowingBackground {
	0% {
	  background-position: 0% 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0% 50%;
	}
  }
  
  @keyframes floatingAnimation {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes TurnOnOpacity {
	from {
	  opacity: 0;
	}
	to{
	  opacity: 1;
	}
  }
  
.floating-animation {
	animation-name: floatingAnimation;
animation-duration: 3s;
animation-iteration-count: infinite;
animation-timing-function: ease-in-out;
}