@import "../variables.scss";
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
/* modal styles */


.overlaybox {
  overflow-x: hidden;
  overflow-y: overlay;
  .close-text {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: TurnOnOpacity ;
    animation-duration: 0.3s;
    animation-delay: 3s;
    animation-iteration-count: 1;
    text-decoration: underline;
    position: absolute;
    bottom: -10vh;
    padding-bottom: 2vh;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .modal-ppt-wrapper {
    width: 72%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 8vh 0 12vh 0;
    background: white;
    border-radius: $borderadius-4;
    height: fit-content;
    position: relative;
    .modal-ctrl {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
          .close-btn {
      position: sticky;
      position: -webkit-sticky;
      top: 2%;
      z-index: 200;
      width: fit-content;
      height: fit-content;
      min-width: auto;
      min-height: auto;
      padding: 0;
      margin: 2% 2% 2% auto;
      backdrop-filter: none;
      border-radius: 50%;
      background: $dark-grey;
      color: $white;
      text-shadow: none;
      transition: all 0.2s ease-out;
      i {
        font-size: 24px;
        padding: 8px;
      }
      &:hover {
        color: $black;
        background: $white;
        transition: all 0.2s ease-in;
      }
    }
    }
    .modal-image-ppt-wrapper {
      display: flex;
      position: relative;
      width: 100%;
      height: fit-content;
      margin: 0;
      padding: 0;
      &:nth-of-type(2) {
        .modal-ppt-image {
          border-radius: $borderadius-4 $borderadius-4 0 0;
        }
      }
      &:nth-last-child(2) {
        overflow: hidden;
          border-radius:  0 0 $borderadius-4 $borderadius-4;
      }
      .modal-ppt-image {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        z-index: 80;
      }
    }
  }


}

// Portrait Styles
.shaigarusiApp {
  &.portrait {
    .overlaybox {
      .modal-ppt-wrapper {
        width: 100%;
        margin: 8vh 0 10vh 0;
        .close-btn {
          height: fit-content;
          margin: 4% 4% 4% auto;
    top: 2%;
        }
        .modal-image-wrapper {
          width: 100%;
          min-width: 100%;
          width: fit-content;
          border-radius: $borderadius-4 $borderadius-4 0 0;
        }
      }
    }
    &.mobile {
      .overlaybox {
        .modal-ppt-wrapper {
          width: 100%;
        }
      }
    }
  }
  &.mobile {
    .overlaybox {
      .modal-ppt-wrapper {
        width: 80%;
      }
      .close-btn {
        i {
          font-size: 18px;
        }
      }
    }
  }
}
