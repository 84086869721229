@import "../variables.scss";

.title-wrapper {
  padding-inline-start: 2vh;
  float: left;
  z-index: 210;
  a {
    text-decoration: none;
  }
  .main-title {
    color: $secondary;
    text-shadow: $text-shadow-dark;
    white-space: nowrap;
    letter-spacing: 0.14rem;
  }
  .desc {
    color: $white;
    text-shadow: $text-shadow-dark;
  }
  #name {
    width: min-content;
    font-family: $font-1;
    line-height: 1;
    font-weight: 900;
    text-transform: uppercase;
    transition: all 0.3s ease-out;
    &:hover {
    color: $secondary;
    transition: all 0.3s ease-in;
    }
  }
  .desc {
    font-weight: 400;
    letter-spacing: 0.05rem;
    margin-top: 0;
    width: 100%;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
}


// Mobile Styles
.shaigarusiApp {
  &.portrait {
    &.mobile {
      .title-wrapper {
        .main-title {
          white-space: normal;
        }
      }
    }
  }
  
  &.mobile {
    .title-wrapper {
      // font-size: 1.625rem;
      .main-title {
        letter-spacing: 0.1rem;
        line-height: 1;
          white-space: nowrap;
      }
      .desc {
        display: none;
        letter-spacing: 0.025rem;
      }
    }
  }
}
