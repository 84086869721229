@import "../variables.scss";

.page-header {
  width: inherit;
  margin: auto 0;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 205;
  position: fixed;
    top: 0;
    left: 0;
    height: fit-content;
  .top-navbar {
    max-width: 1366px;
    min-height: 60px;
    height: 12vh;
    margin: 0 auto;
  width: 100%;
  display: flex;
    align-content: center;
  padding: 4vh 4% 2vh 4%;
  backdrop-filter: blur(8px);
  background: rgba(18, 18, 18, 0.01);
    .nav-left-wrapper, .nav-right-wrapper {
      display: flex;
      align-items: center;
      width: 50%;
      a {
        text-decoration: none;
      }
    }
    .nav-left-wrapper {
      float: left;
      justify-content: flex-start;
    }
    .nav-right-wrapper {
      float: right;
      justify-content: flex-end;
      .top-nav-link {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &.mopen, &.about {
    .top-navbar {
      background: transparent;
      backdrop-filter: none;
    }
  }
}

// Portrait Styles
.shaigarusiApp {
  &.portrait {
    .page-header {
    .top-navbar {
        height: 6vh;
        padding: 4vh 4% 2vh 4%;

        .nav-right-wrapper {
          .top-nav-link {
            margin-right: 8%;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
