@import "../variables.scss";

.menu-button {
   width: 14vw;
   max-width: 14vh;
  transition: all 0.3s ease;
  z-index: 890;
  background: transparent;
  box-shadow: none;
  &:hover {
    background: transparent;
    box-shadow: none;
  }
  i {
    font-size: 48px;
    transform: translateY(-2px);
  }
}

// Mobile Styles
.shaigarusiApp {
  &.mobile{
    .menu-button {
      max-width: 16vh;
      color: $secondary;
      &:hover {
        color: $secondary;
      }
      i {
        font-size: 36px;
      }
   }
  }
}