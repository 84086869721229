@import "../variables.scss";

.nav-wrapper {
  width: 80%;
  height: 100%;
  margin: 0;
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  background: $bg-overlay-dark;
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &.side-bar {
    background: linear-gradient(to left, $bg-overlay-1, rgba(0, 0, 0, 0));
    backdrop-filter: none;
  }

  .nav-inner-wrapper {
    width: 100%;
    list-style: none;
    padding: 0 8vh;
    margin: 28vh 0 0 0;
    z-index: 201;
    .nav-link {
      margin-block-end: 6vh;
      width: fit-content;
      &.selected {
        .nav-button {
          padding-inline-end: 4px;
          color: $secondary;
          &:before {
            width: 100%;
            content: "";
            background: linear-gradient(-45deg, #{$secondary-light} 30%, #{$secondary}, #{$secondary} 70%);
            background-size: 200% 200%;
            animation: GlowingBackground 6s ease infinite;
          }
        }
        &:hover {
          color: $primary-dark;
        }
      }
      .nav-button {
        position: relative;
        text-transform: capitalize;
        min-width: fit-content;
        padding-inline-start: 0;
        padding-inline-end: 0;
        justify-content: flex-start;
        text-decoration: none;
        width: fit-content;
        outline: none;
      }
      &:last-child {
        margin-block-end: 0;
      }
    }
  }
}

// Mobile Styles
.shaigarusiApp {
  &.mobile {
    .nav-wrapper {
      .nav-inner-wrapper {
        padding: 0 12vh;
        .nav-link {
          margin-block-end: 8vh;
          &.selected {
            .nav-button {
              // border: 1px solid $secondary;
              color: $secondary;
            }
          }
        }
      }
    }
  }
}

// Portrait Styles
.shaigarusiApp {
  &.portrait {
    .nav-wrapper {
      .nav-inner-wrapper {
        padding: 0 12%;
        margin: 18vh 0 0 0;
        .nav-link {
          margin-block-end: 6vh;
          &:last-child {
            margin-block-end: 0;
          }
        }
      }
    }
  }
}
