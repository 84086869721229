@import "../variables.scss";
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
/* modal styles */
.overlaybox {
  overflow-x: hidden;
  overflow-y: overlay;
  .close-text {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: TurnOnOpacity ;
    animation-duration: 0.3s;
    animation-delay: 3s;
    animation-iteration-count: 1;
    text-decoration: underline;
    position: absolute;
    bottom: -10vh;
    padding-bottom: 2vh;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .modal-item-wrapper {
    margin: 0;
    position: relative;
    z-index: 21;
    height: fit-content;
    color: $white;
    flex-direction: column;
    align-items: flex-end;
    display: flex;
    margin: 8% 0;
    .modal-ctrl {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      .close-btn {
        position: sticky;
        position: -webkit-sticky;
        top: 2%;
        // left: 2%;
        z-index: 200;
        width: fit-content;
        height: fit-content;
        min-width: auto;
        min-height: auto;
        padding: 0;
        margin: 2% 2% 2% auto;
        backdrop-filter: none;
        border-radius: 50%;
        background: $dark-grey;
        color: $white;
        text-shadow: none;
        transition: all 0.2s ease-out;
        i {
          font-size: 24px;
          padding: 8px;
        }
        &:hover {
          color: $black;
          background: $white;
          transition: all 0.2s ease-in;
        }
      }
    }

    &.horz {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 72%;
      max-width: 864px;
      height: fit-content;
      margin: 3vh 0;
      .modal-additional-images {
        padding: 0 4%;
        grid-template-rows: 100%;
        grid-template-columns: repeat(9, 1fr);
        overflow-y: hidden;
        overflow-x: overlay;
        grid-column-gap: 2%;
        height: fit-content;
        grid-row-gap: 4%;
        height: 14vh;
        width: 92%;
        border-radius: 0;
        right: 0;
        left: auto;
        li {
          margin: 3vh 0;
        }
      }
      .modal-image-wrapper,
      .modal-info-wrapper {
        border: none;
        height: fit-content;
      }
      .modal-image-wrapper {
        border-radius: $borderadius-4 $borderadius-4 0 0 ;
        transform: translateY(0);
          padding-block-end: 0;
          justify-content: center;
          height: 48vh;
          background: $primary-dark;
        &.showGallery {
          padding-block-end: 14vh;
        }
      }
      .modal-info-wrapper {
        border-radius: 0 0 $borderadius-4 $borderadius-4;
        width: 100%;
        .modal-text-wrapper {
          width: 92%;
          .modal-headline-wrapper {
            padding: 3vh 0;
          }
          .modal-details-bar-wrapper {
            padding: 3vh 0;
          }
          .modal-scroll-wrapper {
            overflow-y: overlay;
            overflow-x: hidden;
            scroll-behavior: smooth;
            height: 100%;
            // padding-right: 16px;
              .modal-text-desc:first-of-type {
            padding: 3vh 0 0 0;
            }

            &::-webkit-scrollbar {
              background-color: rgba(0, 0, 0, 0.16);
            }

            &::-webkit-scrollbar:vertical {
              width: 4px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: $borderadius-2;
              background-color: $bg-overlay-3;
            }
          }
        }
      }
    }
    .modal-image-wrapper {
      transform: translateY(1px);
      background: $white;
      border-radius: $borderadius-4 $borderadius-4 0 0;
      width: 100%;
      height: 58vh;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      z-index: 15;
      .modal-image-background {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center center;
        position: absolute;
        top: -5%;
        left: -5%;
        transform: scale(2);
        filter: blur(10px) brightness(0.5);
        z-index: 16;
      }
      .modal-image {
        z-index: 22;
        display: block;
        object-fit: contain;
        height: initial;
        margin: 0;
        max-width: 100%;
        height: 100%;
        max-height: 48vh;
      }
      .modal-video-player {
        height: 48vh;
       }
    }
    .modal-headline-wrapper {
      border-bottom: 1px solid $bg-overlay-1;
      display: flex;
      justify-content: space-between;
      padding: 3vh 0;
      .modal-text-years {
        font-weight: 700;
      }
      .modal-text-name,
      .modal-text-years {
        text-transform: uppercase;
        color: $white;
      }
    }
    .modal-info-wrapper {
      background: linear-gradient(to top,#{$primary} 0%,#{$primary-dark} 100%);
      width: 100%;
      border-radius: 0 0 $borderadius-4 $borderadius-4;
      height: fit-content;
      min-height: 22vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 14;
      overflow-x: hidden;
    }
    .modal-additional-images {
      user-select: none;
      display: grid;
      margin: 0;
      list-style: none;
      // width: 100%;
      // grid-template-columns: repeat(auto-fill, minmax(23.5%, 0)) 0;
      grid-template-columns: repeat(8, 1fr);
      grid-column-gap: 2%;
      grid-row-gap: 12%;
      grid-auto-rows: 8vh;
      // padding: 4% 0 2%;
      height: fit-content;
      min-height: 48px;
      position: absolute;
      padding: 2vh 4%;
      width: 92%;
      left: 0;
      bottom: 0;
      z-index: 99;
      background: $primary-dark;
      // background: linear-gradient(to top,#{$primary-dark} 0%,#{$primary} 100%);

      // backdrop-filter: blur(10px);
      &.scroll {
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
      }
      .additional-image-wrapper {
        position: relative;
        overflow: hidden;
        border-radius: calc(#{$borderadius-2} + 2px);
        cursor: pointer;
        vertical-align: middle;
        display: inline-block;
        padding: 2px;
        background: $bg-overlay-3;
        transition: all 0.2s ease-out;
        &.selected {
          background: $secondary;
          color: $secondary;
          .modal-additional-video {
            .play-icon {
              opacity: 1;
              color: $secondary;
            }
          }
        }
        .modal-additional-image {
          border-radius: $borderadius-2;
          object-fit: cover;
          object-position: center center;
          width: 100%;
          height: 100% !important;
        }
        .modal-additional-video {
          border-radius: $borderadius-2;
          background: $light-grey;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: cover;
          background-position: center center;
          position: relative;
          .modal-additional-image {
            position: absolute;
            top:0;
            left:0;
            z-index: 5;
            filter: brightness(0.4);
          }
          .play-icon {
            border-radius: $borderadius-3;
            color: $white;
            opacity: 0.8;
            text-shadow: $text-shadow-dark;
            z-index: 6;
          }
        }
        &:hover {
          background: $secondary;
          transition: all 0.2s ease-out;
          .modal-additional-video {
            .play-icon {
              opacity: 1;
              color: $secondary;
            }
          }
        }
      }
    }
    .modal-text-wrapper {
      z-index: 20;
      flex-direction: column;
      display: flex;
      width: 92%;
      padding: 0 4%;
      z-index: 20;
      height: fit-content;
      overflow: hidden;

      .modal-text-desc {
        margin: 0;
        line-height: 160%;
        &:first-of-type {
            border-top: 1px solid $bg-overlay-1;
            padding: 3vh 0 0 0;
        }
        &:last-of-type {
          padding: 0 0 3vh 0;
        }
      }

      .modal-details-bar-wrapper {
        width: 100%;
        height: fit-content;
        // border-top: 1px solid $bg-overlay-1;
        display: flex;
        align-items: center;
        padding: 3vh 0;
        position: relative;
        .client-logo {
          width: 80px;
          height: 80px;
          margin-right: 3%;
          border-radius: $borderadius-3;
          border: 1px solid $bg-overlay-1;
        }
        .modal-client-wrapper {
          margin-right: auto;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .modal-text-client-name,
          .modal-text-client-info,
          .modal-text-client-position {
            color: $white;
            margin: 0;
          }
          .modal-text-client-info {
            font-weight: 400;
          }
          .modal-text-client-position {
            margin: 0;
            color: $secondary;
          }
        }
      }
      .modal-tools-wrapper {
        border-top: 1px solid $bg-overlay-1;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        display: flex;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        height: max-content;
        padding: 3vh 0;
        .modal-tool {
          background: $primary-light;
          color: $white;
          padding: $spacing-1 $spacing-2;
          border-radius: $borderadius-2;
          margin: 1%;
          &:first-of-type {
            margin-inline-start: 0;
          }
        }
      }
    }
  }
}

// Portrait Styles
.shaigarusiApp {
  &.portrait {
    .overlaybox {
      .modal-item-wrapper {
        .modal-ctrl {
          .close-btn {
            height: fit-content;
            margin: 4% 4% 4% auto;
      top: 2%;
          }
        }

        .modal-image-wrapper {
          width: 100%;
          min-width: 100%;
          width: fit-content;
          border-radius: $borderadius-4 $borderadius-4 0 0;
          &.showGallery {
            .modal-image {
              max-height: calc(100% - 10vh + 2px);
            }
          }
          .modal-image-background {
            transform: scale(2.5);
          }
          .modal-additional-images {
            grid-auto-rows: 6vh;
            grid-column-gap: 2%;
            grid-row-gap: 8%;
          }
        }
        .modal-info-wrapper {
          padding-top: 2%;
          width: 100%;
          border-radius: 0 0 $borderadius-4 $borderadius-4;
        }
        .modal-tools-wrapper {
          padding: 3vh 0;
        }
      }
    }
    &.mobile {
      .overlaybox {
        .modal-item-wrapper {
          width: 100%;
        }
      }
    }
  }
  &.mobile {
    .overlaybox {
      .modal-item-wrapper {
        .modal-ctrl {
          .close-btn {
            i {
              font-size: 18px;
            }
          }
        }
        .modal-info-wrapper {
          .modal-text-wrapper {
            .modal-additional-video {
              .play-icon {
                padding: 2px 4px;
              }
            }
            .modal-details-bar-wrapper {
              .client-logo {
                width: 56px;
                height: 56px;
              }
            }
          }
        }
      }
    }
  }
}
